import React, { Component, Fragment } from 'react'
import FeaturedProduct from '../components/home/FeaturedProduct'
import Categories from '../components/home/Categories'
import Collection from '../components/home/Collection'
import NewArrival from '../components/home/NewArrival'
import HomeTop from '../components/home/HomeTop'
import NavMenuDesktop from '../components/common/NavMenuDesktop'
import NavMenuMobile from '../components/common/NavMenuMobile'
import HomeTopMobile from '../components/home/HomeTopMobile'
import FooterDesktop from '../components/common/FooterDesktop'
import FooterMobile from '../components/common/FooterMobile'
import AppURL from '../api/AppURL'
import axios from 'axios';

class homepage extends Component {

  componentDidMount(){
    window.scroll(0,0);
    this.GetVisitorDetails();
  }

  GetVisitorDetails = ()=> {

    axios.get(AppURL.VisitorDetails)
    .then().catch()
  }




  render() {
    return (
      <Fragment>
        <div className="Desktop">
        <NavMenuDesktop />
        <HomeTop />
        </div>
        
        <div className="Mobile">
        <br />
        <NavMenuMobile />
        <HomeTopMobile />
        </div>
        
        <FeaturedProduct />
        <NewArrival />
        <Categories />
        <Collection />
        <div className="Desktop">
         <FooterDesktop />
        </div>

        <div className="Mobile">
         <FooterMobile />
        </div>
        
        
      </Fragment>
    )
  }
}

export default homepage