import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router';
import ListGroup from 'react-bootstrap/ListGroup';
import {Container, Row, Col, Card, Button, Modal} from 'react-bootstrap';
import Fiacre from '../../assets/images/pix.png';
import { Link } from 'react-router-dom';



class Profile extends Component {
  render() {
    let name;
    let email;

    if(this.props.user){
      name = this.props.user.name;
      email = this.props.user.email;
    }
    if(!localStorage.getItem('token')){
      return <Redirect to="/login"/>
    }
  
    return (

     
      <Fragment>
        
        <div className="section-title text-center mb-55">
        <h1>User Profile Page</h1>
        </div>   
        <Container>
          <Row>
            
            <Col lg={4} md={4} sm={12}>
            <Card style={{ width: '18rem' }}>
      <Card.Img  className="userprofile" variant="top" src={Fiacre} />
      <Card.Body>
        
      
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroup.Item><Link className="text-link" to="/orderlist">
          <p className="product-name-on-card">Order List</p>
          </Link>
          </ListGroup.Item>
          <ListGroup.Item><Link className="text-link" to="/orderlist">
          <p className="product-name-on-card">Order List</p>
          </Link>
          </ListGroup.Item>
          <ListGroup.Item><Link className="text-link" to="/orderlist">
          <p className="product-name-on-card">Order List</p>
          </Link>
          </ListGroup.Item>
      </ListGroup>
      <Card.Body>
        <Card.Link href="#">Card Link</Card.Link>
        <Card.Link href="#">Another Link</Card.Link>
      </Card.Body>
    </Card>
            </Col>
             <Col lg={8} md={8} sm={12}>
             <ul className="list-group">
            <li className="list-group-item">Name : {name} </li>
            <li className="list-group-item">Email : {email} </li>
          </ul> 
            </Col>
          </Row>
        </Container>
          
      </Fragment>
    )
  }
}

export default Profile