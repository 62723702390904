import React, { Component, Fragment } from 'react';
import axios from 'axios';
import {Container, Row, Col, Card, Button, Modal} from 'react-bootstrap';
import AppURL from '../../api/AppURL';
import cogoToast from 'cogo-toast';
import { Redirect } from 'react-router';


export class OrderList extends Component {
  constructor(){
    super();
    this.state={
         ProductData:[],
         show:false,
         name:"",
         rating:"",
         comment:"",
         product_name:"",
         product_code:"",
         ReviewModal:false
    }
}

  componentDidMount(){
    // let email = this.props.user.email;
    axios.get(AppURL.OrderListByUser(this.props.user.email)).then((response)=>{
     this.setState({ProductData:response.data})  
    }).catch((error)=>{

    })
    


  }

  ReviewModalOpen = (product_code,product_name) =>{
    this.setState({ ReviewModal:true,product_code:product_code,product_name:product_name});

   };

  ReviewModalClose = () =>{
    this.setState({ ReviewModal:false});

   };


  //  handleShow = (event) =>{
  //   this.setState({ show:true});
  //    let Nmsg = event.target.getAttribute('data-message');
  //    let Ntitle = event.target.getAttribute('data-title');
  //    let Ndate = event.target.getAttribute('data-date');

  //    this.setState({Notificationmsg:Nmsg,Notificationtitle:Ntitle,Notificationdate:Ndate})
  //  };

   nameOnChange = (event) =>{
    let name = event.target.value;
    this.setState({name:name})
   }

   RatingOnChange = (event) =>{
    let rating = event.target.value;
    this.setState({rating:rating})
   }

   CommentOnChange = (event) =>{
    let comment = event.target.value;
    this.setState({comment:comment})

   }

   PostReview = ()=>{
    let product_code = this.state.product_code;
    let product_name = this.state.product_name;
    let rating = this.state.rating;
    let comment = this.state.comment;
    let name = this.state.name;


    if(name.length===0){
      cogoToast.error('Name cannot Be Empty, Please input your name',{position:'top-right'}); 

 }else if(comment.length===0){
      cogoToast.error('Comment cannot Be Empty, Please select your city',{position:'top-right'}); 

 }else if(rating.length===0){
      cogoToast.error('Payment cannot Be Empty, Please select your payment method',{position:'top-right'}); 

 }else if(comment.length>150){
      cogoToast.error('Comment cannot Be More Than 150 Characters, Please reduce it',{position:'top-right'}); 

 }else{
      
      let myFormData = new FormData();
      myFormData.append('product_code', product_code);
      myFormData.append('product_name', product_name);
      myFormData.append('reviewer_name', name);
      myFormData.append('reviewer_photo', "");
      myFormData.append('reviewer_rating', rating);
      myFormData.append('reviewer_comments',comment);


      axios.post(AppURL.PostReview,myFormData).then((response)=>{ 
                cogoToast.success('Review Submitted',{position:'top-right'});
                this.ReviewModalClose();
      }).catch(error=>{
           cogoToast.error('Review Not Submitted, Please Try Again',{position:'top-right'}); 
      });
      



 }
    


  
   }



  render() {


    if(!localStorage.getItem('token')){
      return <Redirect to="/login"/>
    }
  
    const MyList = this.state.ProductData;
          
          const MyView = MyList.map((ProductList, i)=>{
            return <div>
               <Col md={6} lg={6} sm={6} xs={6}>
          <h5 className="product-name">{ProductList.product_name}</h5>
          <h6> Quantity = {ProductList.quantity} </h6>
          <p>{ProductList.size} | {ProductList.color}</p>
          <h6>Price = {ProductList.unit_price} x {ProductList.quantity} = {ProductList.total_price}$</h6>
          <h6>Status = {ProductList.order_status}</h6>
          </Col>
          <Button onClick={this.ReviewModalOpen.bind(this,ProductList.product_code,ProductList.product_name)}>Post Review</Button>
          <hr></hr>
            </div>

          })
    return (
      <Fragment>
        <Container>
        <div className="section-title text-center mb-55">
                <h2>Order History By {this.props.user.name}</h2>
        </div>        
                <Card >                
     <Card.Body>
     <Row>
     {MyView}
     </Row>              
     </Card.Body>               
      </Card>


            

        </Container>
        <Modal show={this.state.ReviewModal} onHide={this.ReviewModalClose}>
        <Modal.Header closeButton>
          <h6><i className="fa fa-bell"> Post Your Review</i></h6>
        </Modal.Header>
        <Modal.Body>
        <div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
          <label className="form-label">Your Name</label>
          <input onClick={this.nameOnChange} value={this.props.user.name}  className="form-control" type="text" placeholder=""/>
      </div>
      <div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
          <label className="form-label">Select Rating</label>
          <select  onClick={this.RatingOnChange}   className="form-control">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          </select>
      </div>
      <div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
          <label className="form-label">Your Comment</label>
          <textarea onClick={this.CommentOnChange} rows={2} className="form-control" type="text" placeholder="Enter Your Comment"/> 
      </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.PostReview}>
            Post
          </Button>
          <Button variant="secondary" onClick={this.ReviewModalClose}>
            Close
          </Button>
          
          
        </Modal.Footer>
      </Modal>

      </Fragment>
    )
  }
}

export default OrderList