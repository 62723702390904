import React, { Component, Fragment } from 'react';
import NavMenuDesktop from '../components/common/NavMenuDesktop';
import NavMenuMobile from '../components/common/NavMenuMobile'
import FooterDesktop from '../components/common/FooterDesktop'
import FooterMobile from '../components/common/FooterMobile'
import axios from 'axios';
import AppURL from '../api/AppURL';
import SubCategory from '../components/ProductDetails/SubCategory';


export class ProductSubCategoryPage extends Component {
    constructor({match}){
        super();
        this.state = {
            // Match parameter with subcategory
            Category: match.params.category,
            SubCategory: match.params.subcategory,
            ProductData: [],
        }
    }
    componentDidMount(){

      window.scroll(0,0);

        axios.get(AppURL.ProductlistBySubCategory(this.state.Category,this.state.SubCategory)).then(response=>{
            this.setState({ProductData:response.data})
        }).catch(error=>{

        });
    }


  render() {
    return (
        
      <Fragment>
        <div className="Desktop">
        <NavMenuDesktop />
       

        </div>
        <div className="Mobile">

        <NavMenuMobile />
        </div>
{/* Pass the CategoryData and the productdata to the category page   */}
        <SubCategory Category={this.state.SubCategory} SubCategory={this.state.Category} ProductData={this.state.ProductData} />

        

        <div className="Mobile">
         <FooterMobile />
        </div>

        <div className="Desktop">
         <FooterDesktop />
        </div>

      </Fragment>
    )
  }
}

export default ProductSubCategoryPage