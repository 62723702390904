import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Forget from '../../assets/images/forget.jpg';
import axios from 'axios';
import AppURL from '../../api/AppURL';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router';

class ResetPassword extends Component {
    constructor(){
        super();

        this.state = {
            token:'',
            email:'',
            password:'',
            password_confirmation:'',
            message:'',
            loggedIn:false

        }
    }
        //Reset Password Form Submit Method

        formSubmit = (e) => {
            e.preventDefault();
            const data = {
                token: this.state.token,
                email : this.state.email,
                password : this.state.password,
                password_confirmation : this.state.password_confirmation,
               
            }
              
            axios.interceptors.response.use(response=>{
                return response
            }, error=>{
                if(error.response.status === 401){

                }
                return error;   
            });

            axios.post(AppURL.UserResetPassword,data).then(response=>{
                this.setState({message:response.data.message});
                toast.success(this.state.message,{
                    position: "top-right"
                });
                document.getElementById("formReset").reset();

            }).catch(error=>{
                this.setState({message:error.response.data.message});
                toast.error(this.state.message,{
                    position: "top-right" 
                });
            })


            

        }

        
   
  render() {
    if(localStorage.getItem('token')){
        return <Redirect to="/profile"/>
      }
    return (
        <Fragment>
        <Container>
            <Row className="p-2">
                <Col className="shadow-sm bg-white mt-2" md={12} lg={12} xs={12}>
                    <Row className="text-center">
                        <Col className="d-flex justify-content-center" md={6} lg={6} sm={12} xs={12}>
                            <Form className="onboardForm" onSubmit={this.formSubmit} id="formReset">
                                <h4 className="section-title-login">
                                    RESET PASSWORD
                                </h4>
                                <input type="text" className="form-control m-2" onChange={(e)=>{this.setState({token:e.target.value})}}  placeholder="Enter Your Pin Code" />

                                <input type="email" className="form-control m-2" onChange={(e)=>{this.setState({email:e.target.value})}}  placeholder="Enter Your Email Address" />

                                <input type="password" className="form-control m-2" onChange={(e)=>{this.setState({password:e.target.value})}}  placeholder="Enter Your New Password" />


                                <input type="password" className="form-control m-2" onChange={(e)=>{this.setState({password_confirmation:e.target.value})}}  placeholder="Re-enter New Your Password" />


                                
                                <Button type="submit" className="btn btn-block m-2">Reset Password</Button>
                        


                            </Form>
                            
                        </Col>
                        <Col className="p-0 Desktop m-0" md={6} lg={6} sm={6} xs={6}>

                        <img className="onboardBanner" src={Forget} alt="Login" />
                            
                        
                        </Col>
                    </Row>

                </Col>
            </Row>
         </Container>
        <ToastContainer />
      </Fragment>
    )
  }
}

export default ResetPassword