import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Reg from '../../assets/images/register.jpg';
import { Link, Redirect } from 'react-router-dom';
import AppURL from '../../api/AppURL';
import axios from 'axios';

class Register extends Component {

    constructor(){
        super();
        this.state = {
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            message: '',
            loggedIn:false,

        }

    }

        //Registration Form Submit Method

        formSubmit = (e)=>{
            e.preventDefault();
            const data = {
                name: this.state.name,
                email : this.state.email,
                password : this.state.password,
                password_confirmation : this.state.password_confirmation
            }
            axios.post(AppURL.UserRegister,data).then(response=>{
                localStorage.setItem('token', response.data.token);
                this.setState({loggedIn:true});
                this.props.setUser(response.data.user);
                
  
  
              }).catch(error=>{});
    
           
        }




  render() {

    if(this.state.loggedIn){
        return <Redirect to={"/profile"} />
    }

    if(localStorage.getItem("token")){
        return <Redirect to={"/profile"} />
    }
    return (
        <Fragment>
        <Container>
            <Row className="p-2">
                <Col className="shadow-sm bg-white mt-2" md={12} lg={12} xs={12}>
                    <Row className="text-center">
                        <Col className="d-flex justify-content-center" md={6} lg={6} sm={12} xs={12}>
                            <Form className="onboardForm" onSubmit={this.formSubmit}>
                                <h4 className="section-title-login">
                                    REGISTER
                                </h4>
    <input type="text" className="form-control m-2"  placeholder="Enter Your Name" onChange={((e)=>{this.setState({name: e.target.value})}) } />

    <input type="email" className="form-control m-2"  placeholder="Enter Your Email"  onChange={((e)=>{this.setState({email: e.target.value})}) }/>


    <input type="password" className="form-control m-2"  placeholder="Enter Your Password" onChange={((e)=>{this.setState({password: e.target.value})}) }/>

    <input type="password" className="form-control m-2"  placeholder="Enter Your Password" onChange={((e)=>{this.setState({password_confirmation: e.target.value})}) }/>

                                
                                <Button type="submit" className="btn btn-block m-2">Register</Button>
                                <br /><br />
                                <hr />
                                <p>
                                    <b>Forget My Password? </b>
                                    <Link to="/forgot">Forget Password</Link>
                                </p>

                                <p>
                                    <b>Already Have An Account? </b>
                                    <Link to="/login">Log In</Link>
                                </p>


                            </Form>
                            
                        </Col>
                        <Col className="p-0 Desktop m-0" md={6} lg={6} sm={6} xs={6}>

                        <img className="onboardBanner" src={Reg} alt="Login" />
                            
                        
                        </Col>
                    </Row>

                </Col>
            </Row>
        </Container>
      </Fragment>
    )
  }
}

export default Register