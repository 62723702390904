import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class MegaMenu extends Component {

//Props is used to load the menu properties from the HOMETop component.
    constructor(props){
        super();
    

    }


    // MegaMenu(){
    //     var acc = document.getElementsByClassName("accordion");
    //     var accNum = acc.length;
    //     var i;

    //     for(i=0; i<accNum; i++){
    //         acc[i].addEventListener("click", function (){
    //             this.classList.toggle("active");

    //             var panel = this.nextElementSibling;

    //             if(panel.style.maxHeight){
    //                 panel.style.maxHeight = null;
    //             }else{
    //                 panel.style.maxHeight = panel.scrollHeight+ "px" 
    //             }
    //         } )
    //     }
    // }

    MenuItemClick = (event)=>{
        event.target.classList.toggle("active");
        var panel = event.target.nextElementSibling;
        if(panel.style.maxHeight){
            panel.style.maxHeight = null;
        }else{
            panel.style.maxHeight = panel.scrollHeight+ "px" 
        }

    }



  render() {
    //store the props data in the CatList Constant.
    const CatList = this.props.data ;
    //Map the props data in the CatList Constant.
    const MyView = CatList.map((CatList, i)=>{

        return <div key={i.toString()}>

                       <button onClick={this.MenuItemClick} className="accordion">
                       {/* CatList.category_image and CatList.category_name is used to get the database field
                       from db via api */}
                            <img className="accordionMenuIcon" src={CatList.category_image} />
                            &nbsp; {CatList.category_name}
                        </button>
                        <div className="panel">
                            <ul>
                            {
                            (CatList.subcategory_name).map((Sublist,i)=>{
                                return <li><Link to={"/productsubcategory/"+CatList.category_name+"/"+Sublist.subcategory_name} className="accordionItem">{Sublist.subcategory_name}</Link></li> 
                            })
                            }
                            </ul>

                        </div>
                
                 </div>
        

        

  });
    return (
        <div className="accordionMenuDiv">
            <div className="accordionMenuDivInside">
{/* Load mapped catlist in order to display all cat list from api */}
                {MyView}

            </div>

            </div>



        
    )
  }
}

export default MegaMenu