import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';


class SearchList extends Component {
  render() {
    const MyList = this.props.ProductData;
    const SearchKey = this.props.SearchKey;
    const MyView = MyList.map((ProductList,i)=>{

        if(ProductList.special_price==""){

            //Return element without special price
        return <Col className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
            <Link className="text-link" to={"/productdetails/"+ProductList.id}>
        <Card className="image-box card w-100">
                <img className="center w-75" src={ProductList.image}/>
                <Card.Body>
                    <p className="product-name-on-card">{ProductList.title}</p>
                    <p className="product-price-on-card">Price : ${ProductList.price}</p>
                
               
                </Card.Body>
            </Card> 
            </Link>       
        </Col>


        }else{
            
            //Return common element with special price
        return <Col className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
            {/*Wrap around Link*/}
            
            <Link className="text-link" to={"/productdetails/"+ProductList.id}>
        <Card className="image-box card w-100">
                <img className="center w-75" src={ProductList.image}/>
                <Card.Body>
                    <p className="product-name-on-card">{ProductList.title}</p>
                    <p className="product-price-on-card">Price :<strike>${ProductList.price}</strike>${ProductList.special_price} </p>
                
               
                </Card.Body>
            </Card>  
            </Link>      
        </Col>

        }



    });



    let ProductAllData = this.props.data;

    const breadSuvCatView = ProductAllData.map((bview, i)=>{
 if(bview.subcategory!==""){
        return <><Breadcrumb.Item>
            <Link to={"/productcategory/" + bview.category}>{bview.category}</Link>
        </Breadcrumb.Item><Breadcrumb.Item><Link to={"/productsubcategory/" + bview.category + "/" + bview.subcategory}>i{bview.subcategory}</Link>
            </Breadcrumb.Item></> ;
     }else{
       return <Breadcrumb.Item>
        <Link to={"/productcategory/"+bview.category}>{bview.category}</Link>
        </Breadcrumb.Item> ;
     }



    })
    //  let category = ProductAllData['productList'][0]['category'];
    //  let subcategory = ProductAllData['productList'][0]['subcategory'];

    //  let breadSuvCatView;

    //  if(subcategory && category !==""){
    //     breadSuvCatView = <Breadcrumb.Item>
    //     <Link to={"/productcategory/"+category}>{category}</Link>
    //     </Breadcrumb.Item>;
    //       breadSuvCatView = <Breadcrumb.Item><Link to={"/productsubcategory/"+category+"/"+subcategory}>{subcategory}</Link>
    //       </Breadcrumb.Item> ;
    //  }else{
    //     breadSuvCatView = <Breadcrumb.Item>
    //     <Link to={"/productcategory/"+category}>{category}</Link>
    //     </Breadcrumb.Item> ;
    //  }

    
    return (
         
      <Fragment>

<Container className="text-center" fluid={true}>
            <div className="breadbody">
                <Breadcrumb>
                    <Breadcrumb.Item> <Link to="/">Home</Link> </Breadcrumb.Item>
                    {breadSuvCatView}
                    
                    <Breadcrumb.Item>
                    <Link to={"/productbysearch/"+SearchKey}>{SearchKey}</Link>
                    </Breadcrumb.Item>
                </Breadcrumb>

            </div>
            <div className="section-title text-center mb-40 mt-2">
                <h2>{SearchKey} PRODUCT(s)</h2>

            </div>
    <Row>
        {MyView}
    </Row>
        </Container>

      </Fragment>
    )
  }
}
export default SearchList