import axios from 'axios';
import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card, Button} from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import AppURL from '../../api/AppURL';
import FeaturedLoading from '../placeholder/FavouriteLoading';
import cogoToast from 'cogo-toast';



class Favourite extends Component {
    constructor() {

        super();
        this.state = {
            ProductData:[],
            isLoading:"",
            mainDiv: "d-none",
            PageRefreshStatus:false,
            

        }
    }


    

      //Load axios
      componentDidMount(){
        window.scroll(0,0);

        axios.get(AppURL.FavouriteList(this.props.user.email)).then(response=>{

            this.setState({ProductData:response.data,isLoading:"d-none",mainDiv:""});

        }).catch(error=>{
            cogoToast.error('Product Not Added, Please Try Again',{position:'top-right'});

        })
    }

    removeItem = (event) => {
        let product_code = event.target.getAttribute('data-code');
        let email = this.props.user.email;

        axios.get(AppURL.FavouriteRemove(product_code,email)).then(response=>{
            cogoToast.success('Product Item Removed',{position:'top-right'});
            this.setState({PageRefreshStatus:true});
           

        }).catch(error=>{
            cogoToast.error('Product Not Removed, Please Try Again',{position:'top-right'});
        })

    }

    PageRefresh=()=>{
        if(this.state.PageRefreshStatus===true){
             let URL = window.location;
             return(
                  <Redirect to={URL} />
             )

        }
   }
    


  


  render() {

    if(!localStorage.getItem('token')){
        return <Redirect to="/login"/>
      }

    const FavList = this.state.ProductData;
    const Myview = FavList.map((ProductList,i) =>{
        return <Col className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className="image-box card w-100">
                    <img className="center w-75" src={ProductList.image} alt=""/>
                    <Card.Body>
                    <p className="product-name-on-card">{ProductList.product_name}</p>
                    <Button onClick={this.removeItem} data-code={ProductList.product_code}  className="btn-btn-small"><i className="fa fa-thrash-alt"></i> Remove</Button>
                 </Card.Body>
            </Card>        
        </Col>
    })
    return (
        <Fragment>
        <FeaturedLoading isLoading={this.state.isLoading}/>
        <div className={this.state.mainDiv}>
        <Container className="text-center" fluid={true}>
            <div className="section-title text-center mb-55">
                <h2>My Favourite Items</h2>
                <p>Some Of Our Exclusive Collection You May Like</p>

            </div>
    <Row>

        {Myview}
       
    </Row>
        </Container>
        </div>
        {this.PageRefresh()}
    </Fragment>
    )
  }
}

export default Favourite