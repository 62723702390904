import React, { Component, Fragment } from 'react';
import {Navbar, Row, Col, Container, Button} from 'react-bootstrap'; 
import Logo from '../../assets/images/easyshop.png';
import Bars from '../../assets/images/bar.png';
import {Link, Redirect} from "react-router-dom";
import MegaMenuAll from '../home/MegaMenuAll';
import AppURL from '../../api/AppURL';
import axios from 'axios';


 class NavMenuDesktop extends Component {
  constructor(){
    super();
    this.state={
      SideNavState: "sideNavClose",
      ContentOverState: "ContentOverLayClose",
      SearchKey: "",
      SearchRedirectStatus: false,
      cartCount: 0,
      PageRefreshStatus:false,
    }

    this.SearchOnchange = this.SearchOnchange.bind(this);
    this.SearchOnClick = this.SearchOnClick.bind(this);
    this.searchRedirect = this.searchRedirect.bind(this);

  }


  componentDidMount(){
    let product_code = this.props.product_code;
    axios.get(AppURL.CartCount(product_code)).then((response)=>{
      this.setState({cartCount:response.data})}).catch();
  }

  logout = ()=>{
    localStorage.clear();
  }

 
  SearchOnchange(event){
    let SearchKey = event.target.value;
    // alert(SearchKey);
    this.setState({SearchKey:SearchKey})

  }

  SearchOnClick(){
    if(this.state.SearchKey.length>=2){
      this.setState({SearchRedirectStatus:true})
    }

}

searchRedirect(){
    if(this.state.SearchRedirectStatus===true){
        return <Redirect to={"/productbysearch/"+this.state.SearchKey}/>
    }
}




  ContentOverlayClickHandler = ()=>{
    this.SideNavOpenClose();
  }


  MenuBarClickHandler=()=>{
    this.SideNavOpenClose();
  }




 
  SideNavOpenClose = ()=>{

    let SideNavState = this.state.SideNavState;
    let ContentOverState = this.state.ContentOverState;

    if(SideNavState==="sideNavOpen"){
      this.setState({SideNavState:"sideNavClose",
      ContentOverState:"ContentOverLayClose"
    })
    }else{
          this.setState({SideNavState:"sideNavOpen",
          ContentOverState:"ContentOverLayOpen"

          })

    }


    



  }






  render() {

    

    let buttons;

    if(localStorage.getItem('token')){
     
      buttons = (
      <div>
        <Link to="/notification"><img className="btn" alt=""  src="" />
      
      <i className="fa h4 fa-bell"></i><sup><span className="badge text-white bg-danger">5</span></sup>
      </Link>

      <Link to="/favourite"><img className="btn" alt=""  src="" />
      
      <i className="fa h4 fa-heart"></i><sup><span className="badge text-white bg-danger">3</span></sup>
      </Link>
      <a  className="btn"><i className="fa h4 fa-mobile-alt"></i></a>
      <Link to="/" onClick={this.logout} className="h4 btn">LOGOUT</Link>
      
      <Link to="/register" className="h4 btn">PROFILE</Link>

      <Link to="/cart" className="cart-btn"><i className="fa fa-shopping-cart"></i> {this.state.cartCount} Items</Link> 

      </div>
      )

    }else{
      buttons = (
      <div>
        <Link to="/notification"><img className="btn" alt=""  src="" />
      
      <i className="fa h4 fa-bell"></i><sup><span className="badge text-white bg-danger">5</span></sup>
      </Link>

      <Link to="/favourite"><img className="btn" alt=""  src="" />
      
      <i className="fa h4 fa-heart"></i><sup><span className="badge text-white bg-danger">3</span></sup>
      </Link>
      <a  className="btn"><i className="fa h4 fa-mobile-alt"></i></a>
      <Link to="/login" className="h4 btn">LOGIN</Link>
      
      <Link to="/register" className="h4 btn">REGISTER</Link>

      <Link to="/cart" className="cart-btn"><i className="fa fa-shopping-cart"></i> 0 Items</Link> 



      </div>
        )
      


    }






    return (
      <Fragment>
        <div className="TopSectionDown">
        <Navbar fixed={"top"} className="navbar"  bg="light">
      <Container fluid={"true"} className="fixed-top shadow-sm p-2 mb-0 bg-white">
    <Row>
    
     
      <Col lg={4} md={4} sm={12} xs={12}>
      <img src={Bars} className="bar-img" onClick={this.MenuBarClickHandler} alt="menu-bar" />
      <Link to="/"><img className="nav-logo" alt="Logo" src={Logo} /></Link>
      
     
      </Col>
      <Col className="p-1 mt-1" lg={4} md={4} sm={12} xs={12}>
        <div className="input-group w-100">
          <input onChange={this.SearchOnchange} type="text" className="form-control" />
          <Button onClick={this.SearchOnClick} type="button" className="btn site-btn"><i className="fa fa-search" >
          </i>
          </Button>
        </div>
       
      </Col>
      <Col className="p-1 mt-1" lg={4} md={4} sm={12} xs={12}>
      {buttons}
      </Col>
    </Row>

    
    {this.searchRedirect()}
      </Container>
    </Navbar>
        </div>

    <div className={this.state.SideNavState}>
    <MegaMenuAll />
    </div>
    <div onClick={this.ContentOverlayClickHandler} className={this.state.ContentOverState}>
      
    </div>
        

      </Fragment>
    )
  }
}

export default NavMenuDesktop