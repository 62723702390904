import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

class HomeSlider extends Component {
  render() {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // arrow: true,

      };



      const SliderData = this.props.data;
      const MyView = SliderData.map((SliderList,i)=>{
        return <div key={i.toString()}>
        <img className="slider-img" src={SliderList.slider_image}  alt={`Slider ${i}`}/>
      </div>

      });


    return (
        <div>
        <Slider {...settings}>
            {MyView}
        </Slider>
      </div>
      
    )
  }
}

export default HomeSlider