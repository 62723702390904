import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import validation from '../../validation/validation';
import AppURL from '../../api/AppURL';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




class Contact extends Component {
    constructor(){
        super();
        this.state={
            name:"",
            email:"",
            message:""

        }
    }
        nameOnChange = (event)=>{
        let name = event.target.value;
        // alert(name);
        this.setState({name:name})
        }

        emailOnChange = (event)=>{
            let email = event.target.value;
            // alert(email);

            this.setState({email:email})
        }

        messageOnChange = (event)=>{
            let message = event.target.value;
            // alert(message);
            this.setState({message:message})
        }
        onFormSubmit = (event)=>{
            

            let name = this.state.name;

            let email = this.state.email;

            let message = this.state.message;

            let sendBtn = document.getElementById('sendBtn');
            let contactForm = document.getElementById('contactForm');
            
            if(name.length==0){
                toast.error("Please write your name")
            }
            else if(email.length==0){
                toast.error("Please write your email")
            }
            else if(message.length==0){
                toast.error("Please write your message")
            }
            else if(!validation.NameRegex.test(name)){
                toast.error("Invalid name")
            }
            else{
                sendBtn.innerHTML = "...Sending"
                let  myFormData = new FormData();
                myFormData.append("name",name);
                myFormData.append("email",email);
                myFormData.append("message",message);

                axios.post(AppURL.PostContact, myFormData)
                  .then(function (response) {
                   if(response.status==200 && response.data==1){
                       sendBtn.innerHTML="Send"
                       toast.success('Message Sent Successfully')
                        contactForm.reset();
                        
                   }else{
                    toast.error("error");
                    sendBtn.innerHTML="Not Sent";
                   }
                  })
                  .catch(function (error) {
                    alert("error");
                    sendBtn.innerHTML="Not Sent";
                  });

            }


            event.preventDefault();




        }

  render() {
    return (
        <Fragment>
        <Container>
            <Row className="p-2">
                <Col className="shadow-sm bg-white mt-2" md={12} lg={12} xs={12}>
                    <Row className="text-center">
                        <Col className="d-flex justify-content-center" md={6} lg={6} sm={12} xs={12}>
                            <Form id="contactForm" onSubmit={this.onFormSubmit} className="onboardForm">
                                <h4 className="section-title-login">
                                    CONTACT US
                                </h4>
                                <h6 className="section-sub-title">
                                    PLEASE CONTACT US
                                </h6>
                                <input type="text" onChange={this.nameOnChange} className="form-control m-2"  placeholder="Enter Mobile Number" />
                                

                                <input type="email" onChange={this.emailOnChange} className="form-control m-2"  placeholder="Enter Your Email" />
                                

                                <Form.Control  onChange={this.messageOnChange} className="form-control m-2" placeholder="message"  as="textarea" rows={3} />
                                <Button id="sendBtn" type="submit" className="btn btn-block m-2">Send</Button>


                            </Form>
                            
                        </Col>
                        <Col className="p-0 Desktop m-0" md={6} lg={6} sm={6} xs={6}>
                         <p section-title-contact>
                         5 Banana Island Street Lagos. Email: Support@fiacotech.com </p>
{/* Change Styles to styles */}
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15857.733142980087!2d3.4397002400465624!3d6.466666796398757!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf48ddcf8481b%3A0xc6fb4ccb41574680!2sDesiderata!5e0!3m2!1sen!2sng!4v1687038383932!5m2!1sen!2sng" width="550" height="450" styles="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        
                        
                        </Col>
                    </Row>

                </Col>
            </Row>
        </Container>
        <ToastContainer />
      </Fragment>
    )
  }
}

export default Contact