import React, { Component, Fragment } from 'react'
import {Container, Row, Col,Button,Card} from 'react-bootstrap';
import Product1 from "../../assets/images/product/product1.png";
import AppURL from '../../api/AppURL';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import { Redirect } from 'react-router';

export class Cart extends Component {
 constructor(){
     super();
     this.state = {
          ProductData:[],
          isLoading:"",
          mainDiv:"d-none",
          PageRefreshStatus:false,
          PageRedirectStatus:false,
          confirmBtn:"Confirm Order",
          city:"",
          payment:"",
          name:"",
          address:"",
     }
 }


  //Load axios
    componentDidMount(){
        axios.get(AppURL.CartList(this.props.user.email)).then((response)=>{
            this.setState({ProductData:response.data,isLoading:"d-none",mainDiv:""});
        }).catch(error=>{

        })
    }


    removeItem = (id) => {
     axios.get(AppURL.RemoveCartList(id)).then(response=>{
          if(response.data===1){
               cogoToast.success('Cart Item Removed',{position:'top-right'});
               this.setState({PageRefreshStatus:true});

          }else{
               cogoToast.error('Cart Item Not Removed, Please Try Again',{position:'top-right'});  
          }
     }).catch(error =>{
          cogoToast.error('Cart Item Not Removed, Please Try Again',{position:'top-right'});
     })
      
    }//End Remove Item Method

    //Set the state for each field to the input form data
    cityOnChange = (event)=>{
     let city = event.target.value;
     this.setState({city:city}) 
    }

    
    paymentMethodOnChange = (event)=>{
     let payment = event.target.value;
     this.setState({payment:payment}) 
    }
    nameOnChange = (event)=>{
     let name = event.target.value;
     this.setState({name:name});
    }
    addressOnChange = (event)=>{
     let address = event.target.value;
     this.setState({address:address}); 
    }

    confirmOnClick =()=>{
     let  name = this.state.name;
     let  city = this.state.city;
     let  payment = this.state.payment;
     let  address = this.state.address;
     let  email = this.props.user.email;

     if(name.length===0){
          cogoToast.error('Name cannot Be Empty, Please input your name',{position:'top-right'}); 

     }else if(city.length===0){
          cogoToast.error('City cannot Be Empty, Please select your city',{position:'top-right'}); 

     }else if(payment.length===0){
          cogoToast.error('Payment cannot Be Empty, Please select your payment method',{position:'top-right'}); 

     }else if(address.length===0){
          cogoToast.error('Address cannot Be Empty, Please input your address',{position:'top-right'}); 

     }else{
          let invoice = new Date().getTime();
          let myFormData = new FormData();

          myFormData.append('city', city);
          myFormData.append('payment_method', payment);
          myFormData.append('name', name);
          myFormData.append('email', email);
          myFormData.append('delivery_address', address);
          myFormData.append('invoice_no', invoice);
          myFormData.append('delivery_charge',"00");


          axios.post(AppURL.CartOrder,myFormData).then((response)=>{ 
                    cogoToast.success('Cart Order Received',{position:'top-right'});
                    this.setState({PageRedirectStatus:true});
          }).catch(error=>{
               cogoToast.error('Order Request Not Received, Please Try Again',{position:'top-right'}); 
          });
          



     }

    }



    ItemPlus=(id,quantity,price)=>{

     axios.get(AppURL.CartItemPlus(id,quantity,price)).then(response=>{
          if(response.data===1){
               cogoToast.success('Item Increased',{position:'top-right'});
               this.setState({PageRefreshStatus:true});

          }else{
               cogoToast.error('Item Not Increased, Please Try Again',{position:'top-right'});  
          }
     }).catch(error =>{
          cogoToast.error('Item Not Increased, Please Try Again',{position:'top-right'});
     })
}


ItemMinus=(id,quantity,price)=>{

     axios.get(AppURL.CartItemMinus(id,quantity,price)).then(response=>{
          if(response.data===1){
               cogoToast.success('Item reduced',{position:'top-right'});
               this.setState({PageRefreshStatus:true});

          }else{
               cogoToast.error('Item Not reduced, Please Try Again',{position:'top-right'});  
          }
     }).catch(error =>{
          cogoToast.error('Item Not Increased, Please Try Again',{position:'top-right'});
     })
}

    PageRefresh=()=>{
     if(this.state.PageRefreshStatus===true){
          let URL = window.location;
          return(
               <Redirect to={URL} />
          )

     }
}
 PageRedirect=()=>{
     if(this.state.PageRedirectStatus===true){ 
          return <Redirect to="/orderlist" />
     }

 }



  render() {

     if(!localStorage.getItem('token')){
      return <Redirect to="/login"/>
    }
  

     let totalPriceSum = 0;
     const MyList = this.state.ProductData;
     const Myview = MyList.map((ProductList,i)=>{
          totalPriceSum = totalPriceSum+parseInt(ProductList.total_price);
          return <div> 
               <Card >
               <Card.Body>
               <Row>
                    <Col md={3} lg={3} sm={6} xs={6}>
                         <img className="cart-product-img" src={ProductList.image}  alt="product1"/>
                    </Col>
    
                    <Col md={6} lg={6} sm={6} xs={6}>
                    <h5 className="product-name">{ProductList.product_name}</h5>
                <h6>Quantity: {ProductList.quantity}</h6>
                <p>{ProductList.size}   |  {ProductList.color}  </p>
                <h6>Price = {ProductList.quantity} x {ProductList.unit_price } = {ProductList.total_price}$</h6>
                    </Col>
    
                    <Col md={3} lg={3} sm={12} xs={12}>
                  
                    <Button  onClick={()=>this.removeItem(ProductList.id)} className="btn mt-2 mx-1 btn-lg site-btn"><i className="fa fa-trash-alt"></i></Button>

                    <Button  onClick={()=>this.ItemPlus(ProductList.id,ProductList.quantity, ProductList.unit_price)} className="btn mt-2 mx-1 btn-lg site-btn"><i className="fa fa-plus"></i></Button>
                    <Button  onClick={()=>this.ItemMinus(ProductList.id,ProductList.quantity, ProductList.unit_price)} className="btn mt-2 mx-1 btn-lg site-btn"><i className="fa fa-minus"></i></Button>
    
                    </Col>
                 </Row>              
               </Card.Body>               
           </Card>

          </div>
     

     })
    return (
      <Fragment>
         <Container>   

<div className="section-title text-center mb-55">
                <h2>Product Cart List</h2>
      
      </div>



               <Row>
               <Col className="p-1" lg={7} md={7} sm={12} xs={12} >
               {Myview}
               </Col> 

               <Col className="p-1" lg={5} md={5} sm={12} xs={12} > 
               <div className="card p-2">
               <div className="card-body">
                    <div className="container-fluid ">
                         <div className="row">
<div className="col-md-12 p-1  col-lg-12 col-sm-12 col-12">
     <h5 className="Product-Name text-danger">Total Due: {totalPriceSum}  $</h5>
</div>
</div>
<div className="row">
<div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
     <label className="form-label">Choose City</label>
     <select onClick={this.cityOnChange} className="form-control">
        <option value="">Choose</option>  
        <option value="Abia">Abia</option>
        <option value="Adamawa">Adamawa</option>
        <option value="Akwa Ibom">Akwa Ibom</option>
        <option value="Anambra">Anambra</option>
        <option value="Bauchi">Bauchi</option>
        <option value="Bayelsa">Bayelsa</option>
        <option value="Benue">Benue</option>
        <option value="Borno">Borno</option>
        <option value="Cross River">Cross River</option>
        <option value="Delta">Delta</option>
        <option value="Ebonyi">Ebonyi</option>
        <option value="Edo">Edo</option>
        <option value="Ekiti">Ekiti</option>
        <option value="Enugu">Enugu</option>
        <option value="FCT">Federal Capital Territory (FCT)</option>
        <option value="Gombe">Gombe</option>
        <option value="Imo">Imo</option>
        <option value="Jigawa">Jigawa</option>
        <option value="Kaduna">Kaduna</option>
        <option value="Kano">Kano</option>
        <option value="Katsina">Katsina</option>
        <option value="Kebbi">Kebbi</option>
        <option value="Kogi">Kogi</option>
        <option value="Kwara">Kwara</option>
        <option value="Lagos">Lagos</option>
        <option value="Nasarawa">Nasarawa</option>
        <option value="Niger">Niger</option>
        <option value="Ogun">Ogun</option>
        <option value="Ondo">Ondo</option>
        <option value="Osun">Osun</option>
        <option value="Oyo">Oyo</option>
        <option value="Plateau">Plateau</option>
        <option value="Rivers">Rivers</option>
        <option value="Sokoto">Sokoto</option>
        <option value="Taraba">Taraba</option>
        <option value="Yobe">Yobe</option>
        <option value="Zamfara">Zamfara</option>
    </select>
</div>
<div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
     <label className="form-label">Choose Payment Method</label>
     <select  onClick={this.paymentMethodOnChange}   className="form-control">
     <option value="">Choose</option>
     <option value="Cash On Delivery">Cash On Delivery</option>
     <option value="Cash On Delivery">Stripe</option>
     </select>
</div>
<div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
     <label className="form-label">Your Name</label>
     <input onClick={this.nameOnChange}   className="form-control" type="text" placeholder=""/>
</div>

<div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
     <label className="form-label">Delivery Address</label>
     <textarea  onClick={this.addressOnChange}   rows={2}  className="form-control" type="text" placeholder=""/>
</div>
<div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
     <button onClick={this.confirmOnClick}   className="btn  site-btn">{this.state.confirmBtn}</button>
</div>
</div>
                    </div>
               </div>
               </div>
              
               </Col>
               </Row> 

               
              
                

           </Container>
           

           {this.PageRefresh()}
           {this.PageRedirect()}
      </Fragment>
    )
  }
}

export default Cart