import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

class NewArrivalLoading extends Component {
  render() {
    let isLoading = this.props.isLoading;

    return (
         
        <div className={isLoading}>
<Container className="text-center" fluid={true}>
            <div className="section-title text-center mb-55">
                <h2>NEW ARRIVAL &nbsp;
                    <a className="btn btn-sm ml-2 site-btn" onClick={this.previous}><i class="fa fa-angle-left" aria-hidden="true"></i> </a>
                    &nbsp;
                    <a className="btn btn-sm ml-2 site-btn" onClick={this.next}><i class="fa fa-angle-right" aria-hidden="true"></i> </a>
                </h2>

                <p>Some Of Our Exclusive Collection You May Like</p>
            </div>
<div className="row">

<div className="col-lg-3 col-md-3 col-sm-4 col-6 p-1">
            <a href="" className="card image-box h-100  w-100">
                <div class="ph-picture"></div>
                <div className="ph-item">
                    <div className="ph-col-12">
                        <div className="ph-row">
                            <div className="ph-col-12 small"/>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-4 col-6 p-1">
            <a href="" className="card image-box h-100  w-100">
                <div class="ph-picture"></div>
                <div className="ph-item">
                    <div className="ph-col-12">
                        <div className="ph-row">
                            <div className="ph-col-12 small"/>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-4 col-6 p-1">
            <a href="" className="card image-box h-100  w-100">
                <div class="ph-picture"></div>
                <div className="ph-item">
                    <div className="ph-col-12">
                        <div className="ph-row">
                            <div className="ph-col-12 small"/>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-4 col-6 p-1">
            <a href="" className="card image-box h-100  w-100">
                <div class="ph-picture"></div>
                <div className="ph-item">
                    <div className="ph-col-12">
                        <div className="ph-row">
                            <div className="ph-col-12 small"/>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-4 col-6 p-1">
            <a href="" className="card image-box h-100  w-100">
                <div class="ph-picture"></div>
                <div className="ph-item">
                    <div className="ph-col-12">
                        <div className="ph-row">
                            <div className="ph-col-12 small"/>
                        </div>
                    </div>
                </div>
            </a>
        </div>

     <div className="col-lg-3 col-md-3 col-sm-4 col-6 p-1">
            <a href="" className="card image-box h-100  w-100">
                <div class="ph-picture"></div>
                <div className="ph-item">
                    <div className="ph-col-12">
                        <div className="ph-row">
                            <div className="ph-col-12 small"/>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-4 col-6 p-1">
            <a href="" className="card image-box h-100  w-100">
                <div class="ph-picture"></div>
                <div className="ph-item">
                    <div className="ph-col-12">
                        <div className="ph-row">
                            <div className="ph-col-12 small"/>
                        </div>
                    </div>
                </div>
            </a>
        </div>
         
        <div className="col-lg-3 col-md-3 col-sm-4 col-6 p-1">
            <a href="" className="card image-box h-100  w-100">
                <div class="ph-picture"></div>
                <div className="ph-item">
                    <div className="ph-col-12">
                        <div className="ph-row">
                            <div className="ph-col-12 small"/>
                        </div>
                    </div>
                </div>
            </a>
        </div>

{/* // end row div */}



</div>
</Container>
</div>
      
    )
  }
}

export default NewArrivalLoading