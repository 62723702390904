import React, { Component, Fragment } from 'react';
import { Container, Row, Card} from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import AppURL from '../../api/AppURL';
import NewArrivalLoading from '../placeholder/NewArrivalLoading';
import { Link } from 'react-router-dom';

export class NewArrival extends Component {
    constructor(props){
        super(props);
        this.state = {
          ProductData:[],
          isLoading:"",
          mainDiv:"d-none"
        }
        this.previous = this.previous.bind(this);
        this.next = this.next.bind(this)

    }


    next(){
      this.slider.slickNext();

  }

  previous(){
      this.slider.slickPrev();

  }


    componentDidMount(){
     axios.get(AppURL.ProductListByRemark("NEW")).then(response=>{
      this.setState({ProductData:response.data,isLoading:"d-none",mainDiv:""});
     }).catch(error=>{});
    }
    

    



  render() {

    const NewList = this.state.ProductData;


    const MyView = NewList.map((NewList, i)=>{

      if(NewList.special_price==""){

        return <div>
        <Link className="text-link" to={"/productdetails/"+NewList.id}>
        <Card className="image-box card">
             <img className="center" src={NewList.image}/>
             <Card.Body>
                 <p className="product-name-on-card">{NewList.title}</p>
                 <p className="product-price-on-card">Price : ${NewList.price}</p>
             </Card.Body>
         </Card>
         </Link>
       </div>

      }else{
        
        return <div>
          {/*Wrap around Link*/}
          <Link className="text-link" to={"/productdetails/"+NewList.id}>
        <Card className="image-box card">
             <img className="center" src={NewList.image}/>
             <Card.Body>
                 <p className="product-name-on-card">{NewList.title}</p>
                 <p className="product-price-on-card">Price : <strike className="secondary_text">${NewList.price}</strike> ${NewList.special_price}</p>
             
            
             </Card.Body>
         </Card>
         </Link>
       </div>

      }

    });
    // const MyView = NewList.map(function(NewList, i){
      
    // });






   

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        // arrow: true,

      };

      




    return (
      <Fragment>
        <NewArrivalLoading isLoading={this.state.isLoading}/>
        <div className={this.state.mainDiv}>
        <Container className="text-center" fluid={true}>
            <div className="section-title text-center mb-55">
                <h2>NEW ARRIVAL &nbsp;
                    <a className="btn btn-sm ml-2 site-btn" onClick={this.previous}><i class="fa fa-angle-left" aria-hidden="true"></i> </a>
                    &nbsp;
                    <a className="btn btn-sm ml-2 site-btn" onClick={this.next}><i class="fa fa-angle-right" aria-hidden="true"></i> </a>
                </h2>

                <p>Some Of Our Exclusive Collection You May Like</p>
            </div>
            <Row>
            <h2> Single Item</h2>
        <Slider ref={c=>(this.slider=c)} {...settings}>
          
          {MyView}
          
        </Slider>


            </Row>

        </Container>
        </div>
      </Fragment>
    )
  }
}

export default NewArrival