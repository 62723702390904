import React, { Component } from 'react'
import AppURL from '../../api/AppURL';
import axios from 'axios';
import { Link } from 'react-router-dom';


class MegaMenuAll extends Component {
    constructor(){
        super();
        this.state = {
          //Set Empty data
          MenuData:[]
        }
      }
    
      //Load axios
      componentDidMount(){
        //Use the set data to store the api response data
        axios.get(AppURL.AllCategoryDetails).then(response=>{
          this.setState({MenuData:response.data})
        
        }).catch(error=>{
    
        })
    }


    MenuItemClick = (event)=>{
        event.target.classList.toggle("active");
        var panel = event.target.nextElementSibling;
        if(panel.style.maxHeight){
            panel.style.maxHeight = null;
        }else{
            panel.style.maxHeight = panel.scrollHeight+ "px" 
        }

    }







  render() {
    
        //store the props data in the CatList Constant.
    const CatList = this.state.MenuData;
    //Map the props data in the CatList Constant.
    const MyView = CatList.map((CatList, i)=>{

        return <div key={i.toString()}>

                       <button onClick={this.MenuItemClick} className="accordionAll ">
                       {/* CatList.category_image and CatList.category_name is used to get the database field
                       from db via api */}
                            <img className="accordionMenuIconAll" src={CatList.category_image} />
                            &nbsp; {CatList.category_name}
                        </button>
                        <div className="panelAll">
                            <ul>
                            {
                            (CatList.subcategory_name).map((Sublist,i)=>{
                                return <li><Link to={"/productsubcategory/"+CatList.category_name+"/"+Sublist.subcategory_name} className="accordionItem">{Sublist.subcategory_name}</Link></li>
                                           
                                  
                            })
                            }
                            </ul>

                        </div>
                
                 </div>
        

        

  });

    return (
        <div className="accordionMenuDivAll">
            <div className="accordionMenuDivInsideAll">
{/* Load mapped catlist in order to display all cat list from api */}
                {MyView}

            </div>

            </div>



        
    )
    
  }
}

export default MegaMenuAll