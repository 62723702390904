import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Login from '../../assets/images/login.png';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import AppURL from '../../api/AppURL';


class UserLogin extends Component {

    constructor(){
        super();
        this.state = {
            email: '',
            password: '',
            message: '',
            loggedIn:false,

        }

    }

        //Login Form Submit Method

        formSubmit = (e) => {
            e.preventDefault();
            const data = {
                email: this.state.email,
                password: this.state.password
            }


            axios.post(AppURL.UserLogin,data).then(response=>{
              localStorage.setItem('token', response.data.token);
              this.setState({loggedIn:true});
              this.props.setUser(response.data.user);
            }).catch(error=>{});
        }

    
  render() {

   if(this.state.loggedIn){
    return <Redirect to={"/profile"} />
   }
   
   if(localStorage.getItem("token")){
    return <Redirect to={"/profile"} />
}


    return (
      <Fragment>
        <Container>
            <Row className="p-2">
                <Col className="shadow-sm bg-white mt-2" md={12} lg={12} xs={12}>
                    <Row className="text-center">
                        <Col className="d-flex justify-content-center" md={6} lg={6} sm={12} xs={12}>
                            <Form className="onboardForm" onSubmit={this.formSubmit}>
                                <h4 className="section-title-login">
                                    USER SIGN IN
                                </h4>
                                <input autoComplete='on' type="email" className="form-control m-2" onChange={((e)=>{this.setState({email: e.target.value})}) }  placeholder="Enter Your Email" />

                                <input type="password" className="form-control m-2"  placeholder="Enter Your Password" onChange={((e)=>{this.setState({password:e.target.value})})} />
                                <Button className="btn btn-block m-2" type="submit">Login</Button>
                                <p>
                                    <b>Forget My Password? </b>
                                    <Link to="/forgot">Forget Password</Link>
                                </p>

                                <p>
                                    <b>Don't Have An Account? </b>
                                    <Link to="/register">Sign Up</Link>
                                </p>




                            </Form>
                            
                        </Col>
                        <Col className="p-0 Desktop m-0" md={6} lg={6} sm={6} xs={6}>

                        <img className="onboardBanner" src={Login} alt="Login" />
                            
                        
                        </Col>
                    </Row>

                </Col>
            </Row>
        </Container>
      </Fragment>
    )
  }
}

export default UserLogin