import React, { Component, Fragment } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Apple from '../../assets/images/apple.png';
import Google from '../../assets/images/google.png';
import axios from 'axios';
import AppURL from '../../api/AppURL';
import ReactHtmlParser from 'react-html-parser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class FooterDesktop extends Component {
  constructor() {

    super();
    this.state = {
        address: "",
        android_link: "",
        iOS_link: "",
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
        copyright_link: "",
        loaderDiv:"",
        mainDiv:"d-none"

    }
}


componentDidMount(){

  //store data
   let SiteInfoPurchase = sessionStorage.getItem("AllSiteInfo")
   if(SiteInfoPurchase===null){

     //Get Data if not stored
     axios.get(AppURL.AllSiteInfo).then(response=>{
       let StatusCode = response.status;
       if(StatusCode==200){
           let JsonData = (response.data)[0];
           this.setState({
                          address:JsonData['address'],
                          android_link:JsonData['android_link'],
                          iOS_link:JsonData['iOS_link'],
                          facebook_link:JsonData['facebook_link'],
                          twitter_link:JsonData['twitter_link'],
                          instagram_lin:JsonData['instagram_lin'],
                          copyright_link:JsonData['copyright_link'],       
                          loaderDiv:"d-none",
                          mainDiv:""
                        });

           sessionStorage.setItem("SiteInfoPurchase",JsonData )
       }
         else{
          
           toast.error("Something went wrong",{
             position: "bottom-center"
           });
         }

         }).catch(error=>{
           toast.error("Something went wrong",{
             position: "top-center"
           });
           

         })

   }else{
//Get Data if stored
     this.setState({refund:SiteInfoPurchase,loaderDiv:"d-none",mainDiv:""});
   }
     
 }








  render() {
    return (
      <Fragment>
        <div className="footerback m-0 shadow-sm mt-5 pt-3">
        <Container>
        <Row className="px-0 my-5">
          <Col className="p-2" lg={3} md={3} sm={6} xs={12}>

          <div className={this.state.loaderDiv}>
                            <div class="ph-item">
                                <div class="ph-col-12">
                                    <div class="ph-picture"></div>
                                    <div class="ph-row">
                                        <div class="ph-col-4"></div>
                                        <div class="ph-col-8 empty"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                    </div>
                                </div>
                            </div>

                          </div>





          <div className={this.state.mainDiv}></div>
            <h5 className="footer-menu-title">OFFICE ADDRESS</h5>
            { ReactHtmlParser(this.state.address) }
            <h5 className="footer-menu-title">SOCIAL LINK</h5>
            <a href={this.state.facebook_link} ><i className="fab m-1 h4 fa-facebook"></i></a>
            <a href={this.state.twitter_link}  ><i className="fab m-1 h4 fa-twitter"></i></a>
            <a href={this.state.instagram_link}  ><i className="fab m-1 h4 fa-instagram"></i></a>
            <a href={this.state.instagram_link}  ><i className="fab m-1 h4 fa-youtube"></i></a>
          </Col>

          <Col lg={3} md={3} sm={6} xs={12}>
           <h5 className="footer-menu-title">THE COMPANY</h5>
           <Link to="/about" className="footer-link">About Us</Link><br></br>
           <Link to="/" className="footer-link">Company Profile</Link><br></br>
           <Link to="/contact" className="footer-link">Contact Us</Link><br></br>
          </Col>
          <Col lg={3} md={3} sm={6} xs={12}>
          <h5 className="footer-menu-title">MORE INFO</h5>
          <Link to="/purchase" className="footer-link">How To Purchase</Link><br></br>
          <Link to="/privacy" className="footer-link">Privacy Policy</Link><br></br>
          <Link to="/refund" className="footer-link">Refund Policy</Link><br></br>
          </Col>
          <Col lg={3} md={3} sm={6} xs={12}>
            <h5 className="footer-menu-title">DOWNLOAD APP</h5><br></br>
            <a><img href={this.state.android_link} target="_blank" src={Google} alt=""  /></a><br></br>
            <a><img hret={this.state.iOS_link} target="_blank" className="mt-2" src={Apple} alt=""  /></a><br></br>

            Change Your Language:<br></br>
            <div id="google_translate_element">

            </div>
          </Col>
        </Row>
        </Container>
        <Container fluid={true} className="text-center m-0  pt-3 pb-1 bg-dark">
            <Container >
            <Row>
              <h6 className="text-white"> { ReactHtmlParser(this.state.copyright_link) }</h6>
            </Row>

            </Container>

        </Container>

        </div>
       
      </Fragment>
    )
  }
}

export default FooterDesktop