// react 17.0.2
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/css/custom.css';
import '../src/assets/css/fontawesome.css';
import '../src/assets/css/animate.min.css';
import '../src/assets/css/placeholder-loading.min.css';
import axios from 'axios';



axios.defaults.headers.common['Authorization'] ='Bearer '+localStorage.getItem('token');
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// const state = {
//   budget: [],
//   header: {
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem("token") || null}`,
//       withCredentials: true,
//       "Access-Control-Allow-Origin": "*"
//     },
//   }
// };


// const getters = {
//   allBudget: (state) => state.budget,
// };

// const actions = {
//   async fetchBudget({ commit, state }) {
//     const response = await axios.get(AppURL, state.header);

//     console.log(state.header);
//     commit("setBudget", response.data);
//   },
// };




ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);

