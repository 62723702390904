import React, { Component, Fragment } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import axios from 'axios';
import AppURL from '../../api/AppURL';
import ReactHtmlParser from 'react-html-parser';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';


class Privacy extends Component {
  constructor() {

    super();
    this.state = {
      privacy: "",
        loaderDiv:"",
        mainDiv:"d-none"

    }
}


//Load axios
componentDidMount(){

  //store data
   let SiteInfoPurchase = sessionStorage.getItem("AllSiteInfo")
   if(SiteInfoPurchase===null){

     //Get Data if not stored
     axios.get(AppURL.AllSiteInfo).then(response=>{
       let StatusCode = response.status;
       if(StatusCode==200){
           let JsonData = (response.data)[0]['privacy'];
           this.setState({privacy:JsonData,loaderDiv:"d-none",mainDiv:""});

           sessionStorage.setItem("SiteInfoPurchase",JsonData )
       }
         else{
          
           toast.error("Something went wrong",{
             position: "bottom-center"
           });
         }

         }).catch(error=>{
           toast.error("Something went wrong",{
             position: "top-center"
           });
           

         })

   }else{
//Get Data if stored
     this.setState({privacy:SiteInfoPurchase,loaderDiv:"d-none",mainDiv:""});
   }
     
 }




  render() {
    return (
     <Fragment>
        <Container>
        <div className="breadbody">
            <Breadcrumb>
      <Breadcrumb.Item> <Link to="/">Home</Link> </Breadcrumb.Item>
      <Breadcrumb.Item>
      <Link to="/privacy">Privacy Page</Link>
      </Breadcrumb.Item>
    </Breadcrumb>

            </div>
            <Row className="p-2">
                <Col className="shadow-sm bg-white mt-2" md={12} lg={12} xs={12}>
                <div className={this.state.loaderDiv}>
                            <div class="ph-item">
                                <div class="ph-col-12">
                                    <div class="ph-picture"></div>
                                    <div class="ph-row">
                                        
                                        <div class="ph-col-4"></div>
                                        <div class="ph-col-8 empty"></div>
                                        <div class="ph-col-6"></div>
                                        <div class="ph-col-6 empty"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div> 
                                        <div class="ph-col-12"></div>
                                    </div>
                                </div>
                            </div>

                          </div>

                    <div className={this.state.mainDiv}></div>
                    <h4 className="section-title-login">Privacy Page</h4>

                            <p className="section-title-contact">
                            { ReactHtmlParser(this.state.privacy) }
                            </p>

                    

                </Col>
            </Row>
        </Container>
     </Fragment>
    )
  }
}

export default Privacy