import React, { Component,Fragment } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import Apple from '../../assets/images/apple.png';
import Google from '../../assets/images/google.png';

export class FooterMobile extends Component {
  render() {
    return (
      <Fragment>
      <div className="footerback shadowsm mt-5 pt-3">
      <Container className="text-center">
      <Row className="px-0 my-5">
        <Col className="p-2" lg={3} md={3} sm={6} xs={12}>
          <h5 className="footer-menu-title">OFFICE ADDRESS</h5>
          <p>5 Banana Island Street Lagos.<br></br>Email: Support@fiacotech.com</p>
          <h5 className="footer-menu-title">SOCIAL LINK</h5>
          <a href="/" ><i className="fab m-1 h4 fa-facebook"></i></a>
          <a href="/" ><i className="fab m-1 h4 fa-twitter"></i></a>
          <a href="/" ><i className="fab m-1 h4 fa-instagram"></i></a>
          <a href="/" ><i className="fab m-1 h4 fa-youtube"></i></a>
        </Col>

        
        <Col lg={3} md={3} sm={6} xs={12}>
          <h5 className="footer-menu-title">DOWNLOAD APP</h5>
          <a><img src={Google} alt=""  /></a><br></br>
          <a><img className="mt-2" src={Apple} alt=""  /></a><br></br>

          Change Your Language:<br></br>
            <div id="google_translate_element">

            </div>
        </Col>
      </Row>
      </Container>
      <Container fluid={true} className="text-center m-0  pt-3 pb-1 bg-dark">
          <Container className="text-white">
          <Row>
            <h6>© Fiaco Tech. 2023</h6>
          </Row>

          </Container>

      </Container>

      </div>
     
    </Fragment>
    )
  }
}

export default FooterMobile